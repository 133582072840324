<template>
  <div class="rn-story-area d-flex flex-column align-center bg_color--3 section-ptb-xl" :id="sectionId">
    <h2 class="patky">
      Už to začalo
    </h2>
    <h6>A co se bude dít...</h6>
    <ProgramList />
  </div>
</template>
<script>

import { getId, MENU } from "@/data";
import ProgramList from "./ProgramList.vue";

export default {
  name: 'ProgramSection',
  components: {
    ProgramList
  },
  data() {
    return {
      sectionId: getId(MENU.start.to),
    }
  }
}
</script>