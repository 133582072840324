<template>
  <AboutSection :nav-id="sectionId" :bg-class="bgClass" :reversed="true">
    <h2 class="patky">
      Dorazili jste na místo! Hurá!
    </h2>
    <p>
      Ubytovaní hosté, z parkoviště zamiřte nejdřív na recepci – je hned u areálu.
      Tam se nahlásíte, a pak už hurá dovnitř, kde na vás bude čekat malé občerstvení.
    </p>

    <p>Neubytovaní to mají ještě jednodušší – můžete zamířit rovnou do areálu.
      Nebojte, nenecháme vás tam bloudit, někdo se vás určitě ujme!</p>
  </AboutSection>
</template>
<script>
import AboutSection from "@/components/about/AboutSection.vue";
import { getId, MENU } from "@/data";

export default {
  name: 'ArrivalSection',
  components: {AboutSection},
  data() {
    return {
      sectionId: getId(MENU.arrival.to),
      bgClass: MENU.arrival.bgClass,
    };
  }
}
</script>