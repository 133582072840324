<template>
  <div class="rn-award-area d-flex bg_color--3" id="awards">
    <div class="text-center award-content">
      <ul v-if="programReady" class="tabs rn-award-list">
        <li v-for="(item, i) in items" :key="i">
          <div :class="{'font-600': !!item.bold}">
            <span>{{ item.description }}</span><br/>
            {{ item.time }}
          </div>
        </li>
      </ul>
      <div v-else>
        ...se dozvíte již brzy
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramList',
  data() {
    return {
      programReady: true,
      items: [
        {
          time: 'od 12:00',
          description: 'Příjezd a uvítání hostů'
        },
        {
          time: '13:00',
          description: 'Obřad',
          bold: true
        },
        {
          time: '15:00',
          description: 'Hostina'
        },
        {
          time: '17:00',
          description: 'Krájení dortu'
        },
        {
          time: '19:30',
          description: 'Večerní raut'
        },
        {
          time: 'do ??:??',
          description: 'Večírek'
        },

      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.rn-award-area {
  overflow: hidden;
}

.award-content ul.rn-award-list li a:focus {
  outline: none;
}
</style>
